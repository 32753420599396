import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFill from '@iconify/icons-ant-design/instagram-outlined';
import whatsappFill from '@iconify/icons-ic/round-whatsapp';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Tooltip } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'FaceBook',
    icon: facebookFill,
    socialColor: '#1877F2',
    path: 'https://web.facebook.com/profile.php?id=61553103005953'
  },
  // {
  //   name: 'Instagram',
  //   icon: instagramFill,
  //   socialColor: '#E02D69',
  //   path: 'https://instagram.com/?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr'
  // },
  {
    name: 'Whatsapp',
    icon: whatsappFill,
    socialColor: 'green',
    path: 'https://wa.me/+50935138181/'
  }
  // { name: 'Twitter', icon: twitterFill, path: '', }
];

const LINKS = [
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' }
    ]
  },
  {
    headline: 'Contact',
    children: [
      { name: '+50934198181', href: '#' },
      { name: '+50935138181', href: '#' },
      { name: 'info@RLP Center.com', href: '#' },
      { name: 'Delmas 65, Rue Condore #6', href: '#' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Améliorez la gestion de votre entreprise de loterie avec RLP Center.
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => {
                const { name, icon, path, socialColor } = social;
                return (
                  <Link key={name} href={path} target="_blank" rel="noopener">
                    <Tooltip title={name} placement="top">
                      <IconButton
                        color="inherit"
                        sx={{
                          color: socialColor,
                          '&:hover': {
                            bgcolor: `${socialColor}08`
                          }
                        }}
                      >
                        <Icon icon={icon} sx={{ width: 20, height: 20 }} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                );
              })}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2021. Tous les droits sont réservés
        </Typography>
      </Container>
    </RootStyle>
  );
}
